.goBackButton {
  background-color: white;
  margin: 0 10px;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid black;
  padding: 10px 18px;
  cursor: pointer;
}

p {
  margin: 30px;
}

.note {
  border: 1px solid black;
  margin: 20px;
  padding: 15px;
  position: relative;
}

.noteContent {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
}

.currentFolder {
  padding: 10px;
  margin: 20px 5px;
  text-align: center;
}